//Gifter application - constants.js

export const GifterServiceBaseUrl = 'https://clj2opkf48.execute-api.us-west-2.amazonaws.com/Dev'; 
export const GifterSharedServiceBaseUrl = ''; 
export const OKTA_CLIENT_ID = "0oaz4wp39lvqwhCL90h7";
export const OKTA_ISSUER_URL = "https://ssodev.wbd.com/oauth2/default";
export const OKTA_REDIRECT_URL = window.location.origin + '/callback';
export const OKTA_URL = "https://ssodev.wbd.com";
export const IDLE_TIMEOUT = "60";
export const IDLE_WARN_TIMEOUT = "30";

